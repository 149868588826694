import {Response} from "../../../_metronic/helpers";
import {defaultIllustration, Illustration} from "../misc/Illustration";

export type Post = {
    id: number;
    full_name: string;
    mobile: string;
    email_address: string;
    illustration: Illustration;
    message: string;
    is_approved: number;
    likes: number;
};

export type PostPaginate = Response<Post[]>;

export type PostList = {
    data: Post[];
};

export const defaultPost: Post = {
    id: 0,
    full_name: "",
    mobile: "",
    email_address: "",
    illustration: defaultIllustration,
    message: "",
    is_approved: 0,
    likes: 0
};
