import React from "react";
import {Route, Routes} from "react-router-dom";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {SuspenseView} from "../../components/misc/SuspenseView";
import {Sections} from "../../helpers/sections";
import {useAuth} from "../../modules/auth";
import PostIndex from "../../sections/core/posts/pages/Index";
import PostShow from "../../sections/core/posts/pages/Show";

const postsBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.CORE_POSTS,
        path: "/core/posts/",
        isSeparator: false,
        isActive: false,
    },
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];

const PostRoutes: React.FC = () => {
    const {currentUser, hasRoles} = useAuth();

    return (
        <Routes>
            <Route
                index
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={[]}>{"Posts"}</PageTitle>
                        {/*{hasRoles(currentUser, ["Administrator"]) ? (*/}
                        <PostIndex/>
                        {/*) : (*/}
                        {/*  <Navigate to={"/error/403"}></Navigate>*/}
                        {/*)}*/}
                    </SuspenseView>
                }
            />


            <Route
                path="/:id"
                element={
                    <SuspenseView>
                        <PageTitle
                            breadcrumbs={postsBreadcrumbs}
                            showPageTitle={false}
                        >
                            {"Show"}
                        </PageTitle>
                        <PostShow />
                    </SuspenseView>
                }
            />
        </Routes>
    );
};

export default PostRoutes;
