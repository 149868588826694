/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { ResetPassword } from "./components/ResetPassword";

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div
        className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        // className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sigma-1/14.png"
          )})`,
        }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <h1 className="text-center mb-5">
            Welcome to the Dashboard
          </h1>

          {/* begin::Wrapper */}
          <div className="w-lg-s00px w-sm-400px bg-body rounded shadow-sm p-10 p-lg-15 m-5">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
