import React from "react";
import {Column} from "react-table";
import {Post} from "../../../../models/core/Post";
import {Restricted} from "../../../../modules/auth/AuthAccessControl";
import {CustomHeader} from "../../../../modules/table/columns/CustomHeader";
import {TextCell} from "../../../../modules/table/columns/TextCell";
import {BadgeCell} from "../../../../modules/table/columns/BadgeCell";
import {updatePost} from "../../../../requests/core/Post";
import {KTSVG} from "../../../../../_metronic/helpers";


const PostsColumns: ReadonlyArray<Column<Post>> = [
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Full Name" className="min-w-125px"/>
        ),
        id: "full_name",
        Cell: ({...props}) => (
            <TextCell text={props.data[props.row.index].full_name}/>
        ),
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Mobile" className="min-w-125px"/>
        ),
        id: "mobile",
        Cell: ({...props}) => (
            <TextCell text={props.data[props.row.index].mobile}/>
        ),
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Emaill address" className="min-w-125px"/>
        ),
        id: "email_address",
        Cell: ({...props}) => (
            <TextCell text={props.data[props.row.index].email_address}/>
        ),
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Likes" className="min-w-125px"/>
        ),
        id: "likes",
        Cell: ({...props}) => (
            <TextCell text={props.data[props.row.index].likes}/>
        ),
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Post" className="min-w-125px"/>
        ),
        id: "post",
        Cell: ({...props}) => (
            <>
                <button type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target={`#kt_modal_${props.data[props.row.index].id}`}
                >
                    View the post
                </button>
                <div className="modal modal-preview fade" tabIndex={-1} id={`kt_modal_${props.data[props.row.index].id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Post #{props.data[props.row.index].id}</h5>
                                <div
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className={'illustration'}>
                                    <img src={`${props.data[props.row.index].illustration.background}?v=12345`}
                                         alt={'Illustration'}
                                    style={{width: '100%'}}/>
                                    <div className={'post-message'} style={{color: props.data[props.row.index].illustration.color}}>{props.data[props.row.index].message}</div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title="Approved?" className="min-w-125px"/>
        ),
        id: "is_approved",
        Cell: ({...props}) => (
            props.data[props.row.index].is_approved ?
                <BadgeCell status={'Yes'} color={'light-success'} align={'left'}/> :
                <BadgeCell status={'No'} color={'light-danger'} align={'left'}/>
        ),
    },
    {
        Header: (props) => (
            <Restricted to="manage-core">
                <CustomHeader
                    tableProps={props}
                    title="Actions"
                    className="text-end min-w-100px"
                />
            </Restricted>
        ),
        id: "actions",
        Cell: ({...props}) => (
            <Restricted to="manage-core">
                <a
                    className="btn btn-sm btn-light-success me-2 my-2"
                    onClick={async () => {
                        props.data[props.row.index].is_approved = 1;

                        updatePost(props.data[props.row.index].id, props.data[props.row.index])
                            .then((response) => {
                                window.location.reload()
                            });
                    }}
                >
                    Approve
                </a>
                <a
                    className="btn btn-sm btn-light-danger"
                    onClick={async () => {
                        props.data[props.row.index].is_approved = 0;

                        updatePost(props.data[props.row.index].id, props.data[props.row.index])
                            .then((response) => {
                                window.location.reload()
                            });
                    }}
                >
                    Disapprove
                </a>
            </Restricted>
        ),
    },
];

export {PostsColumns};
