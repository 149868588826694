import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {MasterLayout} from "../../_metronic/layout/MasterLayout";
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";
import IamModuleRoutes from "./iam/ModuleRoutes";
import CoreModuleRoutes from "./core/ModuleRoutes";
import PostRoutes from "./core/PostRoutes";

export const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                <Route path="auth/*" element={<Navigate to="/dashboard"/>}/>

                {/* Pages */}
                <Route path="dashboard" element={<PostRoutes/>}/>
                {/*<Route path="dashboard" element={<DashboardWrapper/>}/>*/}
                {/* Pages */}

                {/* IAM section */}
                <Route path="iam/*" element={<IamModuleRoutes/>}/>

                {/* Core section */}
                <Route path="core/*" element={<CoreModuleRoutes/>}/>

                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/error/404"/>}/>
            </Route>
        </Routes>
    );
};
