export enum Actions {
  CREATE = 1,
  SHOW,
  EDIT,
  COPY,
  FILTER,
  EXPORT,
  SELECT,
  OPTION,
  PAUSE,
  CANCEL,
  RESUME,
  DATE_RANGE_PICKER,
  TABS
}

export enum PageTypes {
  INDEX = "index",
  CREATE = "create",
  EDIT = "edit",
  SHOW = "show",
  REPORT = "report",
  ERROR = "error",
  ARCHIVED = "archived",
  DELETE = "delete",
}

export enum MafToastType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  PENDING = "pending",
}
