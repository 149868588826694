import axios from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {KTCard, KTCardBody, KTSVG} from "../../../../../_metronic/helpers";
import {KTCardHeader} from "../../../../../_metronic/helpers/components/KTCardHeader";
import {generatePageTitle} from "../../../../helpers/pageTitleGenerator";
import {Sections} from "../../../../helpers/sections";
import {PageTypes,} from "../../../../helpers/variables";
import {defaultPermission, Permission,} from "../../../../models/iam/Permission";
import {useMafApp} from "../../../../modules/general/MafApp";
import {getPermission,} from "../../../../requests/iam/Permission";
import {defaultPost, Post} from "../../../../models/core/Post";
import {getPost} from "../../../../requests/core/Post";
import {isElementLoaded} from "../../../../helpers/general";

const PostShow: React.FC = () => {
    const [post, setPost] = useState<Post>(defaultPost);

    const navigate = useNavigate();

    const mafApp = useMafApp();

    let {id} = useParams();

    const setFontSize = () => {
        var containers = document.querySelectorAll('.illustration');

        // Calculate slope and y-intercept
        var m = (18 - 8) / (415 - 130);
        var b = 18 - m * 415;

        containers.forEach(function(container, i) {
            var description = container.querySelector('.post-message') as HTMLElement;

            var containerSize = Math.min(250, 444);
            // var containerSize = Math.min(container.clientWidth, container.clientHeight);

            // Calculate font size using linear equation
            var fontSize = m * containerSize + b;

            // Set font size for description
            if (description) {
                description.style.fontSize = fontSize + 'px';
            }
        });
    }

    window.addEventListener('resize', setFontSize);

    isElementLoaded('.modal-preview').then((selector) => {
        setFontSize();
    });
    
    useEffect(() => {
        if (id) {
            // get the post we need to edit from the database
            getPost(parseInt(id)).then((response) => {
                if (axios.isAxiosError(response)) {
                    // we were not able to fetch the post to edit so we need to redirect
                    // to error page
                    navigate("/error/404");
                } else if (response === undefined) {
                    navigate("/error/400");
                } else {
                    // we were able to fetch current post to edit
                    setPost(response);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        mafApp.setPageTitle(
            generatePageTitle(
                Sections.CORE_POSTS,
                PageTypes.SHOW,
                `Post ${post.id}`
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post]);

    return (
        <KTCard>
            <KTCardHeader text={`Post #${post.id}`}/>

            <KTCardBody>
                <div className="modal modal-preview fade show" style={{position: 'relative', display: 'block'}}>
                    <div className="modal-dialog" style={{maxWidth: 'none', margin: 0}}>
                        <div className="modal-content" style={{width: '100%', background: 'none', boxShadow: 'none'}}>
                            <div className="modal-body">
                                <div className={'illustration'}>
                                    <img src={`${post.illustration.background}?v=12345`}
                                         alt={'Illustration'}
                                         style={{width: '100%'}}/>
                                    <div className={'post-message'}
                                         style={{color: post.illustration.color}}>{post.message}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    );
};

export default PostShow;
