import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { SuspenseView } from "../../components/misc/SuspenseView";

const CoreModuleRoutes: React.FC = () => {
  const PostRoutes = lazy(() => import("./PostRoutes"));

  return (
    <Routes>
      {/* Sections */}
      <Route
        path="posts/*"
        element={
          <SuspenseView>
            <PostRoutes />
          </SuspenseView>
        }
      ></Route>
    </Routes>
  );
};

export default CoreModuleRoutes;
