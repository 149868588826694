import {exportObjects, extractErrors} from './requests'
import axios from "axios";
import {Actions, MafToastType} from "./variables";
import {AlertMessageGenerator} from "./AlertMessageGenerator";
import {GenericErrorMessage} from "./form";

export const formatDateToMonthDayYear = (date: string | undefined) => {
  // F d, Y => March, 7 2023
  if (date) {
    const fullDate = createDateFromString(date);

    return `${fullDate.toLocaleString("default", {
      month: "long",
    })} ${fullDate.getDate()}, ${fullDate.getFullYear()} ${
      date.split(" ")?.[1]
    }`;
  }

  return "";
};

export const createDateFromString = (date: string) => {
  // for the month we do -1 because new Date accepts month Index and not month
  return new Date(
    parseInt(date.split("-")[0]),
    parseInt(date.split("-")[1]) - 1,
    parseInt(date.split("-")[2])
  );
};

export const toDateTimeString = (date: Date) => {
  return (
    date.toDateString() +
    ", " +
    padLeft(date.getHours()) +
    ":" +
    padLeft(date.getMinutes()) +
    ":" +
    padLeft(date.getSeconds())
  );
};

const padLeft = (nr: any, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", options);
};

export const urlChecker = (input: any) => {
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

  return urlPattern.test(input);
};

export const capitalizeFirstLetter = (inputString: string | undefined) => {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  } else {
    return "";
  }
};


export const isElementLoaded = async (selector: any) => {
  while ( document.querySelector(selector) === null) {
    await new Promise( resolve =>  requestAnimationFrame(resolve) )
  }
  return document.querySelector(selector);
};

export const genericExportHandler = (krysApp: any, exportQuery: string, exportEndpoint: string) => {
  // we already have the query for our export request ready based on filters
  // we just need to do the api call
  exportObjects(exportEndpoint, exportQuery).then((response) => {
    if (axios.isAxiosError(response)) {
      // we need to show the errors
      krysApp.setAlert({message: extractErrors(response).join(' '), type: MafToastType.ERROR})
    } else if (response === undefined) {
      // show generic error message
      krysApp.setAlert({message: GenericErrorMessage, type: MafToastType.ERROR})
    } else {
      // we need to check the status of the response
      if (response.data.status === 'ready' && response.data.url !== undefined) {
        krysApp.setAlert({
          message: new AlertMessageGenerator('', Actions.EXPORT, MafToastType.SUCCESS).message,
          type: MafToastType.SUCCESS,
        })

        const link = document.createElement('a')
        link.href = response.data.url

        link.click()
      } else if (response.data.status === 'pending') {
        krysApp.setAlert({
          message: new AlertMessageGenerator('', Actions.EXPORT, MafToastType.PENDING).message,
          type: MafToastType.PENDING,
        })
      }
    }
  })
}