import { Response } from "../../../_metronic/helpers";

export type Category = {
    id: number;
    name: string;
};

export type CategoryPaginate = Response<Category[]>;

export type CategoryList = {
    data: Category[];
};

export const defaultCategory: Category = { id: 0, name: "" };
