import {Response} from "../../../_metronic/helpers";
import {Category, defaultCategory} from "./Category";

export type Illustration = {
    id: number;
    name: string;
    background: string;
    category: Category;
    color: string;
};

export type IllustrationPaginate = Response<Illustration[]>;

export type IllustrationList = {
    data: Illustration[];
};

export const defaultIllustration: Illustration = {id: 0, name: "", background: "", category: defaultCategory, color: ''};
