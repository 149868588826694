/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useAuth } from "../../../../../app/modules/auth";
import { Restricted } from "../../../../../app/modules/auth/AuthAccessControl";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const { currentUser, hasRoles } = useAuth();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title="Dashboard"
        fontIcon="bi-app-indicator"
      />

      <Restricted to="view-iam">
        {hasRoles(currentUser, ["Administrator"]) && (
          <>
            <SidebarMenuItem
              to="/iam/permissions"
              icon="shield"
              title="Permissions"
              fontIcon="bi-app-indicator"
            />

            <SidebarMenuItem
              to="/iam/roles"
              icon="setting"
              title="Roles"
              fontIcon="bi-app-indicator"
            />
          </>
        )}

        <SidebarMenuItem
          to="/iam/users"
          icon="profile-user"
          title="Users"
          fontIcon="bi-app-indicator"
        />

          {/*<SidebarMenuItem*/}
          {/*    to="/core/posts"*/}
          {/*    icon="clipboard"*/}
          {/*    title="Posts"*/}
          {/*    fontIcon="bi-app-indicator"*/}
          {/*/>*/}
      </Restricted>
    </>
  );
};

export { SidebarMenuMain };
