import React, {useEffect, useMemo, useState} from "react";

import { KTCard, KTCardBody, QUERIES } from "../../../../../_metronic/helpers";
import { KTCardHeader } from "../../../../../_metronic/helpers/components/KTCardHeader";
import MafTable from "../../../../components/tables/MafTable";
import { generatePageTitle } from "../../../../helpers/pageTitleGenerator";
import { Sections } from "../../../../helpers/sections";
import { PageTypes } from "../../../../helpers/variables";
import { useMafApp } from "../../../../modules/general/MafApp";
import { ListViewProvider } from "../../../../modules/table/ListViewProvider";
import { QueryRequestProvider } from "../../../../modules/table/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
} from "../../../../modules/table/QueryResponseProvider";
import {EXPORT_ENDPOINT, getPosts} from "../../../../requests/core/Post";
import { PostsColumns } from "../core/TableColumns";
import {isElementLoaded} from "../../../../helpers/general";
import {ExportCardAction} from "../../../../components/misc/CardAction";

const PostIndex = () => {
  const mafApp = useMafApp();
  const [exportQuery, setExportQuery] = useState<string>('')

  useEffect(() => {
    mafApp.setPageTitle(
      generatePageTitle(Sections.IAM_PERMISSIONS, PageTypes.INDEX)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFontSize = () => {
    var containers = document.querySelectorAll('.illustration');

    // Calculate slope and y-intercept
    var m = (18 - 8) / (415 - 130);
    var b = 18 - m * 415;

    containers.forEach(function(container, i) {
      var description = container.querySelector('.post-message') as HTMLElement;

      var containerSize = Math.min(250, 444);
      // var containerSize = Math.min(container.clientWidth, container.clientHeight);

      // Calculate font size using linear equation
      var fontSize = m * containerSize + b;

      // Set font size for description
      if (description) {
        description.style.fontSize = fontSize + 'px';
      }
    });
  }

  window.addEventListener('resize', setFontSize);

  isElementLoaded('.modal-preview').then((selector) => {
    setFontSize();
  });

  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        id={QUERIES.PERMISSIONS_LIST}
        requestFunction={getPosts}
      >
        <ListViewProvider>
          <KTCard>
            <KTCardHeader
              text={"All Posts"}
              actions={[
                {
                  endpoint: EXPORT_ENDPOINT,
                  color: "primary",
                  title: "Export",
                  icon: "fa-download",
                },
              ]}
            />

            <KTCardBody>
              <IndexTable />
            </KTCardBody>
          </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

const IndexTable = () => {
  const modelData = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => modelData, [modelData]);
  const columns = useMemo(
    () => PostsColumns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <MafTable
      data={data}
      columns={columns}
      model={modelData.length > 0 ? modelData[0] : null}
      isLoading={isLoading}
    />
  );
};
export default PostIndex;
