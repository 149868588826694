import axios, {AxiosError, AxiosResponse} from "axios";
import {createFormData} from "../../helpers/requests";
import {
    Post,
    PostList,
    PostPaginate,
} from "../../models/core/Post";
import {Permission} from "../../models/iam/Permission";

const API_URL = process.env.REACT_APP_API_URL;
const ENDPOINT = `${API_URL}/core/posts`;
export const EXPORT_ENDPOINT = `${ENDPOINT}/export`;

export const getAllPosts = async (): Promise<
    PostList | AxiosError | undefined
> => {
    return axios
        .get(ENDPOINT + "/all?sort[]=name")
        .then((response: AxiosResponse<PostList>) => response.data)
        .catch((error) => {
            return error;
        });
};

export const getPosts = (query?: String): Promise<PostPaginate> => {
    let url = `${ENDPOINT}`;

    if (query) {
        url += `?${query}`;
    }

    return axios
        .get(url)
        .then((response: AxiosResponse<PostPaginate>) => response.data)
        .catch((error) => {
            return error;
        });
};

export const getPost = async (
    id: number
): Promise<Post | AxiosError | undefined> => {
    return await axios
        .get(ENDPOINT + "/" + id)
        .then((res) => res.data.data)
        .catch((error) => {
            return error;
        });
};

export const storePost = async (
    post: any
): Promise<Post | AxiosError | undefined> => {
    let formData = createFormData(post);

    return await axios
        .post(ENDPOINT, formData)
        .then((res) => res.data.data)
        .catch((error) => {
            return error;
        });
};

export const updatePost = async (
    id: number,
    post: any
): Promise<Post | AxiosError | undefined> => {
    console.log(post)

    let formData = createFormData(post);

    formData.append("_method", "put");

    return await axios
        .post(ENDPOINT + "/" + id, formData)
        .then((res) => res.data.data)
        .catch((error) => {
            return error;
        });
};
